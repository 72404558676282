import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { StyledAction, StyledContents } from "baseui/card";
import { Input } from "baseui/input";
import { Notification } from "baseui/notification";
import { Label3, Paragraph1 } from "baseui/typography";
import firebase from 'firebase';
import React from "react";
import UserApi from '../../firebase/UserApi';
import { useHistory } from 'react-router-dom';
// import TagManager from 'react-gtm-module';

const greenStyle = {
  "paddingLeft": '15px',
  "marginBottom": '-15px',
  "marginTop": '35px',
  "color": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
}
const MAX_VERIFICATION_TRIES = 5;

const SignupFlow = (props) => {
  const [error, setError] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [authStep, _setAuthStep] = React.useState({ history: ["signup"], current: "signup" });
  const setAuthStep = (newState) => _setAuthStep({ history: [...authStep.history, newState], current: newState });
  const [email, setEmail] = React.useState(window.localStorage.getItem("emailForSignIn") ? window.localStorage.getItem("emailForSignIn") : "");
  const [password, setPassword] = React.useState("");
  const [verificationTries, setVerificationTries] = React.useState(0);

  const history = useHistory();

  // React.useEffect(() => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: 'pageview',
  //     page: {
  //       url: window.location.href,
  //       title: document.title
  //     }
  //   });
  // }, []);

  const components = {
    "unvalidated": CheckEmail,
    "signup": Signup,
    "sorry": Sorry

  };

  const setUI = (func) => {
    setError(undefined);
    setIsLoading(true);
    return func();
  };

  const fbCatch = (e) => {
    setIsLoading(false);
    setError(e.message);
    return Promise.reject();
  };

  const checkEmail = () => {
    if (props.firebase.emailIsVerified) {
      setEmail(window.localStorage.getItem("emailForSignIn"))
      console.log(email)
      setAuthStep("signup");
      setIsLoading(false);
    } else {
      firebase.auth()
        .fetchSignInMethodsForEmail(email)
        .then((signInMethods) => {
          setIsLoading(false);
          const isNewUser = signInMethods.length === 0;

          if (isNewUser) setAuthStep("signup");
          else setError("Your email address is already registered. Please use another email address");
        })
        .catch(fbCatch);
    }
  }

  const signup = () => {
    firebase.auth()
      .createUserWithEmailAndPassword(email, password)
      .then((data) => {
        console.log(data.user);
        window.localStorage.removeItem('emailForSignIn');
        UserApi.updateClaims(data.user.uid)
          .then(data => {
            setIsLoading(false)
          })
          .catch(e => setError(e.message))
      })
      .catch(fbCatch);
  }

  const askForEmailAndVerify = () => {
    console.log(props.firebase.emailIsVerified);

    if (props.firebase.emailIsVerified) {
      setEmail(window.localStorage.getItem("emailForSignIn"))
      console.log(email)
      setAuthStep("signup");
      setIsLoading(false);
    } else {
      firebase.auth()
        .fetchSignInMethodsForEmail(email)
        .then((signInMethods) => {
          const isNewUser = signInMethods.length === 0;

          console.log(isNewUser);

          if (isNewUser) {
            firebase.auth().sendSignInLinkToEmail(email, props.firebase.actionCodeSettings)
              .then(() => {
                window.alert("Please check your email for a verification link");
                // The link was successfully sent. Inform the user.
                // Save email to session storage since we'll be loading a new tab from email, which will re-initialize firebase
                window.localStorage.setItem('emailForSignIn', email);
                setVerificationTries(verificationTries + 1);
                if (verificationTries < MAX_VERIFICATION_TRIES) {
                  setIsLoading(false)
                } else {
                  window.localStorage.removeItem('emailForSignIn')
                  setAuthStep("sorry");
                }
              })
              .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
              });
          } else {
            setIsLoading(false);
            setError("Your email address is already registered in our software"); 
          }
        })
        .catch(fbCatch);
    }
  }

  const AuthStep = components[authStep.current];

  const internalProps = {
    email,
    setEmail,
    password,
    setPassword,
    signup: () => setUI(signup),
    error,
    verify: () => setUI(askForEmailAndVerify),
    checkEmail: () => setUI(checkEmail),
    isLoading,
    askForEmailAndVerify: () => setUI(askForEmailAndVerify),
    firebase: props.firebase
  };



  return (
    <Block width='100%' maxWidth="400px" margin="auto">
      <AuthStep {...internalProps}></AuthStep>
      <Error errorMessage={error} />
    </Block>
  );
};

const Error = ({ errorMessage }) => <>{errorMessage ? <Notification kind='negative'>{() => errorMessage}</Notification> : null}</>;

const CheckEmail = ({ email, setEmail, checkEmail, isLoading }) => (
  <>
    <Label3 $style={greenStyle}>Sign up with email</Label3>
    <StyledContents>
      <Input placeholder='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
      <Button overrides={{ BaseButton: { style: { width: "100%", marginTop: "10px" } } }} style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} onClick={() => checkEmail()} disabled={!email} isLoading={isLoading}>
        Verify Email
      </Button>
    </StyledContents>
  </>
);

const Signup = ({ email, setEmail, password, setPassword, signup, isLoading, children, askForEmailAndVerify, firebase }) => (
  <>
    <Label3 $style={greenStyle}>Sign up with email</Label3>
    <StyledContents>
      <Input placeholder='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
      {firebase.emailIsVerified ? <Input placeholder='Create Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} /> : null}
      {children}

      <Button overrides={{ BaseButton: { style: { width: "75%", marginTop: "10px" } } }} style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} onClick={firebase.emailIsVerified ? () => signup() : () => askForEmailAndVerify()} isLoading={isLoading}>
        {firebase.emailIsVerified ? "Sign up" : "Verify Email" }
      </Button>
    </StyledContents>
  </>
);

const Sorry = ({ password, setPassword, signup, showRequestResetPassword, isLoading, children }) => (
  <>
    <Label3 $style={greenStyle}>Hmm... we're having an issue verifying your email address. Please ensure it is spelled correctly. If you're still having trouble, please wait a little while and try again.</Label3>
  </>
);

export default SignupFlow;
