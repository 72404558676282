import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useStyletron } from 'styletron-react';
import { setUserDataLoading } from './actions/ui';
import { loadUserData } from './actions/userData';
import BeginSubscription from "./billing/BeginSubscription";
import ReinstateSubscription from './billing/ReinstateSubscription';
import RecoverableError from './components/error/RecoverableError';
import { UnrecoverableError } from './components/error/UnrecoverableError';
import { ADMIN_PAGE_LINK, PROFILE_LINK } from './components/flows/linkConstants';
import LoadingScreen from './components/LoadingScreen';
import ResetPassword from './components/login/ResetPassword';
import SubscriptionLayout from './components/SubscriptionLayout'
import ToolBar from './components/Toolbar';
import { AuthUserContext, withAuthentication } from './firebase/session';
import { AdminPage } from './pages/AdminPage';
import Profile from './pages/AppPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import { Logout } from "./pages/LogoutPage";
import Maintenance from "./Maintenance";
import moment from "moment";
// import TagManager from 'react-gtm-module';

// TagManager.initialize({
//   gtmId: "G-1GCNPDZ4NQ"
// });

const NavigatedApp = () => {
  const maintenance = false;

  let { authUser } = React.useContext(AuthUserContext);

  if(maintenance) {
    return (
      <Maintenance />
    );
  }

  if (authUser === undefined) {
    return <LoadingScreen />
  } else if (authUser !== undefined && (authUser === null || !authUser.email)) {
    return <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/logout" component={Logout} />
        <Route path="/reset-password" component={ResetPassword} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  }

  if(authUser != undefined) {
    const { paid, admin, inTrials, startTrialTimestamp } = authUser.claims;

    if(inTrials == true && startTrialTimestamp) {
      const currentDate = moment();
      const trialDate = moment(Number(startTrialTimestamp["_seconds"]) * 1000);

      const days = currentDate.diff(trialDate, "days");

      if(days > 7) {
        return <BrowserRouter>
          <Switch>
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/payment-success" component={LoadingScreen} />
            <Route path='/reinstate' component={ReinstateSubscription} />
            <Route path='/purchase' component={BeginSubscription} />
            <Redirect to='/purchase' />
          </Switch>
        </BrowserRouter>
      } else {
        return <BrowserRouter>
          <Switch>
            <Route exact path="/logout" component={Logout} />
            <Route path="/app" component={FirebaseApp} />
            <Route path='/reinstate' component={ReinstateSubscription} />
            <Route path='/purchase' component={BeginSubscription} />
            <Redirect to={PROFILE_LINK} />
          </Switch>
        </BrowserRouter>
      }
    }

    if (inTrials == true || (!paid && !inTrials)) {
      return <BrowserRouter>
        <Switch>
          <Route path="/app" component={FirebaseApp} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/payment-success" component={LoadingScreen} />
          <Route path='/reinstate' component={ReinstateSubscription} />
          <Route path='/purchase' component={BeginSubscription} />
          <Redirect to={PROFILE_LINK} />
        </Switch>
      </BrowserRouter>
    }

    return <BrowserRouter>
      <Switch>
        {admin ? <Route path={ADMIN_PAGE_LINK} component={AdminPage} /> : undefined}
        <Route exact path="/logout" component={Logout} />
        <Route path="/app" component={FirebaseApp} />
        <Route exact path="/unsubscribe" component={SubscriptionLayout} />
        <Redirect to={PROFILE_LINK} />
      </Switch>
    </BrowserRouter>
  }
}

const App = ({ userDataLoading, setUserDataLoading, loadUserData, error }) => {
  const [css] = useStyletron();
  const match = useRouteMatch();

  let { authUser } = React.useContext(AuthUserContext);
  React.useEffect(() => {
    loadUserData(authUser);
  }, [authUser]);

  return (
    <div className={css({ width: "100vw", height: "100vh", margin: 0 })}>
      <Route path={`${match.path}/:tab`}>
        {userDataLoading ?
          <LoadingScreen /> :
          <>
            <ToolBar />
            {error === 'irrecoverable' ? <UnrecoverableError /> : <Profile />}
            <RecoverableError />
          </>}
      </Route>
    </div>
  );
}

const FirebaseApp = connect(state => ({
  userDataLoading: state.ui.loading,
  error: state.ui.error
}), {
  setUserDataLoading,
  loadUserData
})(App);

export default withAuthentication(NavigatedApp);
